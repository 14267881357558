import React, { FC } from 'react';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import Layouts from '../../components/layout';
import OfficeInfo from '../../components/pagesComponents/ContactUs/OfficeInfo';
import ContactsTitle from '../../components/pagesComponents/ContactUs/ContactsTitle';
import TextSpan from '../../components/pagesComponents/MainPage/TextSpan';
import SectionTitle from '../../components/common/SectionTitle';
import FixedButton from '../../components/common/FixedButton';
import HubSpotForm from '../../components/pagesComponents/ContactUs/HubSpotForm';
import SEO from '../../components/SEO';

import Calendar from '../../assets/img/calendar.svg';

import './contactUs.scss';

const ContactUsPage: FC = () => {
    useScrollToTop();
    return (
        <Layouts>
            <SEO
                pageTitle="Blackbird Lab - Contact Us"
                pageDescription="Fill the form below to get in touch, or write to us at info@blackbird-lab.com. We're here to help..."
                pageImage="https://blackbird-image-cdn.s3.eu-central-1.amazonaws.com/contact-us/contact-us.png"
            />
            <section className="contact-us">
                <div className="contact-us__wrapper">
                    <div className="contact-us__body">
                        <ContactsTitle
                            subtitleText="Contact Us"
                            titleText="Let’s Talk"
                            titleSubtype="small"
                            titleType="section"
                            text="Fill the form below to get in touch, or write to us at info@blackbird-lab.com. We're here to help."
                        />
                        <HubSpotForm />
                    </div>
                </div>
                <SectionTitle type="main" title="Our offices" />
                <div className="contact-us__map-wrapper">
                    <iframe
                        src="https://maps.google.com/maps?width=600&amp;height=450&amp;hl=en&amp;q=%D0%93%D0%BE%D0%BB%D0%BE%D1%81%D1%96%D1%97%D0%B2%D1%81%D1%8C%D0%BA%D0%B8%D0%B9%20%D0%BF%D1%80%D0%BE%D1%81%D0%BF%D0%B5%D0%BA%D1%82%2C%20132+(Blackbird-Lab)&amp;ie=UTF8&amp;t=&amp;z=15&amp;iwloc=B&amp;output=embed"
                        width="600"
                        height="450"
                        className="contact-us__map"
                        loading="lazy"
                    />
                    <OfficeInfo
                        title="Ukraine"
                        timezone="Europe/Kiev"
                        time="GMT+3"
                        phone="+380-97-976-00-92"
                        email="info@blackbird-lab.com"
                        address="Holosiivskyi prospekt, 132, Kyiv"
                        zipcode="02000 Ukraine"
                    />
                </div>
                <div className="contact-us__map-wrapper">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3976.5351616278585!2d-74.0506812!3d4.6766225!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9a8d8805c351%3A0x1b9e8805fb49676d!2sCra.%2013%20%2393-35%2C%20Bogot%C3%A1%2C%20Colombia!5e0!3m2!1sen!2sua!4v1690814700398!5m2!1sen!2sua&z=15"
                        width="600"
                        height="450"
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className="contact-us__map"
                    />
                    <OfficeInfo
                        title="Colombia, Bogotá"
                        time="GMT-5"
                        timezone="America/Bogota"
                        phone="+57 300 929 0440"
                        email="info@blackbird-lab.com"
                        address="Cra. 13 # 93-35, Localidad de Chapinero, Bogotá, Cundinamarca, Colombia"
                        zipcode="111511 Colombia"
                    />
                </div>
                <TextSpan
                    buttonLink="https://calendly.com/ydrozd"
                    buttonText="Book a meeting"
                    title="Let’s work together"
                    buttonIcon={Calendar}
                >
                    Even a 5-minute intro call could potentially save you countless hours of endless
                    back-and-forth emails.
                </TextSpan>
            </section>
            <FixedButton />
        </Layouts>
    );
};

export default ContactUsPage;
