import React, { FC } from 'react';
import MainTitle from '../../../common/MainTitle';
import './ContactsTitle.scss';
import { ITitleProps } from '../../../../types/types';

const ContactsTitle: FC<ITitleProps> = (props) => {
    return (
        <div data-testid="contacts-title" className="contact-us-page__headline">
            <div className="contact-us-page__wrapper">
                <MainTitle
                    subtype={props.titleSubtype}
                    type={props.titleType}
                    subtitleF={props.subtitleText}
                    title={props.titleText}
                />
            </div>

            <p className="contact-us-page__info">{props.text}</p>
        </div>
    );
};

export default ContactsTitle;
