import React, { FC, useRef, useState } from 'react';
import { Link } from 'gatsby';
import PhoneInput from 'react-phone-input-2';
import classNames from 'classnames';

import ButtonIcon from '../../../common/ButtonIcon';
import check from '../../../../assets/img/common/check.svg';
import arrowRight from '../../../../assets/img/arr-right.svg';

import 'react-phone-input-2/lib/high-res.css';
import './HubSpotForm.scss';

type Options = {
    id: number;
    text: string;
}[];

const HubSpotForm: FC = () => {
    const formRef = useRef<HTMLFormElement>(null);
    const [firstname, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [requestDetails, setRequestDetails] = useState('');
    const [hearAbout, setHearAbout] = useState('');
    const [consentCheck, setConsentCheck] = useState('');
    const [disabled, setIsDisabled] = useState(true);
    const [helperText, setHelperText] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const options: Options = [
        { id: 0, text: 'Managed Services or Team Extension' },
        { id: 1, text: 'End-to-end platform development' },
        { id: 2, text: 'MVP development' },
        { id: 3, text: 'Looking for intro / consultancy' },
    ];

    const handleChangeInput = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const { name: eName, value, checked } = e.currentTarget;

        switch (eName) {
            case 'firstname':
                setName(value);
                break;
            case 'email':
                const isValid = isValidEMail(value);
                if (isValid) {
                    setEmail(value);
                    setHelperText('');
                } else {
                    setEmail(value);
                    setHelperText('Please, use the correct email');
                }
                break;
            case 'request_details':
                setRequestDetails(value);
                break;
            case 'how_did_you_hear_about_blackbird':
                setHearAbout(value);
                break;
            case 'consent_check':
                setConsentCheck('checked');
                if (Boolean(checked)) {
                    setIsDisabled(false);
                } else {
                    setIsDisabled(true);
                }
                break;
        }
    };

    const isValidEMail = (emailValue: string) => {
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (emailValue.match(validRegex)) {
            console.log('Valid email address!');
            return true;
        } else {
            console.log('Invalid email address!');
            return false;
        }
    };

    const handlePhoneChange = (value: string) => {
        setPhone(value);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setName('');
        setPhone('');
        setEmail('');
        setRequestDetails('');
        setHearAbout('');
        setConsentCheck('');
        setIsDisabled(true);
        setIsFormSubmitted(true);
    };

    return (
        <>
            {!isFormSubmitted && (
                <div onSubmit={handleSubmit} className="form">
                    <form ref={formRef} id="footerContactForm">
                        <div className="form__radioSelector">
                            <label className="form__radioSelector__label">
                                What services are you interested in? <br />
                                <span>Please select the most applicable answer</span>
                                <br />
                            </label>
                            {options.map((option) => (
                                <label key={option.id} className="form__radioSelector__option">
                                    <input
                                        type="radio"
                                        id="service"
                                        name="service"
                                        value={option.text}
                                    />
                                    {option.text}
                                </label>
                            ))}
                        </div>
                        <div className="form__questionPart">
                            <div className="form__question">
                                <label className="form__question__label">Name</label>
                                <input
                                    className="form__question__input"
                                    type="text"
                                    id="firstname"
                                    name="firstname"
                                    required={true}
                                    onChange={handleChangeInput}
                                    value={firstname}
                                />
                            </div>
                            <div className="form__question">
                                <label className="form__question__label">Phone number</label>
                                <PhoneInput
                                    inputProps={{
                                        className: 'form__question__input',
                                        name: 'phone',
                                    }}
                                    specialLabel=""
                                    containerClass="form__question"
                                    country="us"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                />
                            </div>
                            <div className="form__question">
                                <label className="form__question__label">Email</label>
                                <input
                                    className="form__question__input"
                                    type="email"
                                    id="email"
                                    name="email"
                                    required={true}
                                    onChange={handleChangeInput}
                                    value={email}
                                />
                                <p className="form__helperText">{helperText}</p>
                            </div>
                            <div className="form__question">
                                <label className="form__question__label">
                                    Please share details on this request | How can we help?
                                </label>
                                <textarea
                                    className="form__question__input"
                                    id="request_details"
                                    name="request_details"
                                    required={true}
                                    rows={2}
                                    wrap="soft"
                                    onChange={(event) => handleChangeInput(event as any)}
                                    value={requestDetails}
                                />
                            </div>
                            <div className="form__question">
                                <label className="form__question__label">
                                    How did you hear about Blackbird?
                                </label>
                                <input
                                    className="form__question__input"
                                    type="text"
                                    id="how_did_you_hear_about_blackbird"
                                    name="how_did_you_hear_about_blackbird"
                                    onChange={handleChangeInput}
                                    value={hearAbout}
                                />
                            </div>

                            <div className="form__checkbox__wrapper">
                                <label>
                                    <input
                                        type="checkbox"
                                        id="consent_check"
                                        name="consent_check"
                                        required={true}
                                        value={consentCheck}
                                        onChange={handleChangeInput}
                                    />
                                    I consent to Blackbird Lab processing my personal information as
                                    set out in the{' '}
                                    <Link className="link" to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </label>
                            </div>
                        </div>
                        <div className="tooltip">
                            <button className="btn" type="submit" disabled={disabled}>
                                Submit
                                <ButtonIcon icon={check} />
                            </button>
                            <span
                                className={classNames('tooltip__tooltipText', {
                                    'tooltip__tooltipText--hidden': disabled,
                                })}
                            >
                                Please, accept our Privacy Policy first
                            </span>
                        </div>
                    </form>
                </div>
            )}
            {isFormSubmitted && (
                <div className="wrapper">
                    <p>Thank you! We’ll be in touch within the next couple of days.</p>
                    <button onClick={() => setIsFormSubmitted(false)} className="btn">
                        Start again
                        <ButtonIcon icon={arrowRight} />
                    </button>
                </div>
            )}
        </>
    );
};

export default HubSpotForm;
