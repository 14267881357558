import React, { FC } from 'react';
import SectionTitle from '../../../common/SectionTitle';
import Clock from '../../../common/Clock';
import './OfficeInfo.scss';
import '../OurContacts/OurContacts.scss';

interface IOfficeInfo {
    timezone: string;
    time: string;
    title: string;
    phone: string;
    email: string;
    address: string;
    zipcode: string;
}

const OfficeInfo: FC<IOfficeInfo> = (props) => {
    return (
        <div data-testid="our-offices" className="our-offices__wrapper">
            <div className="our-offices__cities">
                <div className="our-office__city">
                    <SectionTitle type="city" title={props.title} />
                    <span className="our-offices__timezone">{props.time}</span>
                    <Clock timezone={props.timezone} />
                    <a href={`tel:${props.phone}`} className="contacts__phone">
                        {props.phone}
                    </a>
                    <a href={`mailto:${props.email}`} className="contacts__email">
                        {props.email}
                    </a>
                    <span className="contacts__phone">{props.address}</span>
                    <span className="contacts__phone">{props.zipcode}</span>
                </div>
            </div>
        </div>
    );
};

export default OfficeInfo;
